/* App.css */

/* Global Styles */
body {
  font-family: 'Garet', sans-serif;
  color: #242659;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Header Styles */
header {
  background-color: #7161EB;
  padding: 1rem 0;
}

header nav ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
}

header nav ul li {
  margin: 0 1rem;
}

header nav ul li a {
  color: white;
  text-decoration: none;
}

/* Main Content Styles */
main {
  flex-grow: 1;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

h1, h2, h3 {
  color: #242659;
}

/* Button Styles */
.cta-button {
  background-color: #7161EB;
  color: white;
  border: none;
  padding: 10px 20px;
  font-family: 'Garet', sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #5c4ed6;
}

/* Footer Styles */
footer {
  background-color: #f4f4f4;
  padding: 1rem 0;
  text-align: center;
}

footer nav a {
  color: #242659;
  text-decoration: none;
  margin: 0 1rem;
}

/* Page-specific Styles */

/* Home Page */
.home {
  text-align: center;
}

/* About Page */
.about h2 {
  margin-top: 2rem;
}

/* FAQ Page */
.faq-item {
  margin-bottom: 2rem;
}

.faq-item h2 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

/* Contact Page */
.contact form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 2rem auto;
}

.contact form div {
  margin-bottom: 1rem;
}

.contact form label {
  display: block;
  margin-bottom: 0.5rem;
}

.contact form input,
.contact form textarea {
  width: 100%;
  padding: 0.5rem;
  font-family: 'Garet', sans-serif;
}

.contact form textarea {
  height: 150px;
}

.contact-info {
  margin-top: 2rem;
}