@font-face {
  font-family: 'Garet-Heavy';
  src: url('./fonts/garet/Garet-Heavy.ttf') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Garet';
  src: url('./fonts/garet/Garet-Book.ttf') format('woff2');
  font-style: normal;
}

body {
  font-family: 'Garet', sans-serif;
  color: #242659;
}

.cta-button {
  background-color: #7161EB;
  color: white;
  border: none;
  padding: 10px 20px;
  font-family: 'Garet', sans-serif;
  font-weight: bold;
  cursor: pointer;
}