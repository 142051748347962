.home {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.logo {
  display: inline-block;
  text-decoration: none;
  color: #f8f8f8;
  font-size: 1.5rem;
}

/* Hero Section */
.hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
  gap: 2rem;
}

.hero-content {
  flex: 1;
  text-align: left;
}

.hero-image {
  flex: 1;
  text-align: right;
}

.hero-image img {
  border-radius: 10px;
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.hero h1 {
  font-size: 3.5rem;
  color: #7161EB;
  margin-bottom: 1rem;
  line-height: 1.2;
  font-family: 'Garet', sans-serif;
}

.hero h2 {
  font-size: 1.8rem;
  color: #242659;
  margin-bottom: 1rem;
  font-family: 'Garet', sans-serif;
}

.hero h3 {
  font-size: 1.2rem;
  color: #242659;
  margin-bottom: 1.5rem;
  font-family: 'Garet', sans-serif;
}

.app-badges {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1rem;
  font-family: 'Garet', sans-serif;
}

.app-badges p {
  margin-right: 1rem;
  font-size: 1rem;
  color: #242659;
  font-family: 'Garet', sans-serif;
}

.app-store-badge,
.google-play-badge {
  font-size: 2rem;
  margin: 0 0.5rem;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.app-store-badge {
  color: #000000;
}

.google-play-badge {
  color: #3DDC84;
}

/* Vision Statement */
.vision-statement {
  padding: 1rem 0;
  background-color: #f8f8f8;
  text-align: center;
}

.vision-statement h2 {
  color: #7161EB;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-family: 'Garet', sans-serif;
}

.main-vision {
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto 3rem;
  line-height: 1.6;
  font-family: 'Garet', sans-serif;
}

.vision-points {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  font-family: 'Garet', sans-serif;
}

.vision-point {
  flex-basis: calc(33.333% - 2rem);
  min-width: 250px;
  font-family: 'Garet', sans-serif;
}

.vision-point h3 {
  color: #7161EB;
  margin-bottom: 1rem;
  font-family: 'Garet', sans-serif;
}

.vision-point p {
  font-size: 1rem;
  line-height: 1.5;
  font-family: 'Garet', sans-serif;
}
/* Vision Statement */

/* Services */
.services {
  padding: 4rem 0;
  background-color: #ffffff;
}

.services h2 {
  text-align: center;
  color: #7161EB;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  font-family: 'Garet', sans-serif;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  font-family: 'Garet', sans-serif;
}

.service-item {
  text-align: center;
  padding: 2rem;
  background-color: #f8f8f8;
  border-radius: 8px;
  font-family: 'Garet', sans-serif;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.service-icon {
  font-size: 3rem;
  color: #7161EB;
  margin-bottom: 1rem;
}

.service-item h3 {
  color: #242659;
  margin-bottom: 0.5rem;
  font-family: 'Garet', sans-serif;
}

.service-item p {
  font-size: 0.9rem;
  color: #666;
  font-family: 'Garet', sans-serif;
}
/* Services */

/* Features Section */
.features {
  margin-bottom: 4rem;
}

.features h2 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  color: #242659;
  font-family: 'Garet', sans-serif;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.feature-item {
  text-align: center;
  padding: 2rem;
  background-color: #f8f8f8;
  border-radius: 8px;
  font-family: 'Garet', sans-serif;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.feature-icon {
  font-size: 3rem;
  color: #7161EB;
  margin-bottom: 1rem;
}

/* How It Works Section */
.how-it-works {
  margin-bottom: 4rem;
}

.how-it-works h2 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  color: #242659;
  font-family: 'Garet', sans-serif;
}

.steps {
  display: flex;
  justify-content: space-between;
  font-family: 'Garet', sans-serif;
  flex-wrap: wrap;
}

.step {
  flex-basis: 22%;
  text-align: center;
}

.step-number {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  background-color: #7161EB;
  color: white;
  font-weight: bold;
  font-family: 'Garet', sans-serif;
  margin-bottom: 1rem;
}

/* Sitter Callout Section */
.sitter-callout {
  background-color: #f0f0f0;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 4rem;
}

.sitter-callout h2 {
  color: #7161EB;
  margin-bottom: 1rem;
  font-family: 'Garet', sans-serif;
}

/* CTA Section */
.cta {
  text-align: center;
  background-color: #f8f8f8;
  padding: 3rem;
  border-radius: 8px;
}

.cta h2 {
  margin-bottom: 1rem;
  font-size: 2.5rem;
  color: #242659;
  font-family: 'Garet', sans-serif;
}

.cta-button-home {
  display: inline-block;
  background-color: #7161EB;
  color: white;
  padding: 1rem 2rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
  font-family: 'Garet', sans-serif;
}

.cta-button-home:hover {
  background-color: #5c4ed6;
}

.pulse {
  animation: pulse 2s infinite;
  font-family: 'Garet', sans-serif;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* Responsive Design */
/* @media (max-width: 768px) {
  .hero {
    flex-direction: column;
    text-align: center;
  }

  .vision-point {
    flex-basis: 100%;
  }

  .hero-content {
    order: 2;
  }

  .hero-image {
    order: 1;
    margin-bottom: 2rem;
  }

  .hero h1 {
    font-size: 2.5rem;
  }

  .hero h2 {
    font-size: 1.5rem;
  }

  .hero h3 {
    font-size: 1rem;
  }

  .app-badges {
    justify-content: center;
  }

  .early-access-form {
    flex-direction: column;
    align-items: center;
  }

  .early-access-form input,
  .early-access-form .cta-button-home {
    width: 100%;
    max-width: 300px;
    margin-bottom: 1rem;
    border-radius: 4px;
  }

  .steps {
    flex-direction: column;
  }

  .step {
    margin-bottom: 2rem;
  }
} */




/* Responsive Design */
@media (max-width: 768px) {
  .home {
    padding: 1rem;  /* Smaller padding for smaller screens */
  }

  .hero {
    flex-direction: column;
    text-align: center;
    gap: 1rem;  /* Smaller gap for stacked elements */
  }

  .hero-content, .hero-image {
    text-align: center; /* Center content for better visibility */
    flex: unset; /* Remove flex grow to handle layout more easily */
    width: 100%; /* Full width for better space utilization */
  }

  .hero h1 {
    font-size: 2.5rem; /* Smaller font size for headlines */
  }

  .hero h2, .hero h3 {
    font-size: 1.5rem;  /* Uniform font size for subheadings */
  }

  .vision-point {
    flex-basis: 100%;  /* Full width for each vision point */
  }

  .services-grid, .feature-grid {
    grid-template-columns: 1fr; /* Single column grid layout */
  }

  .app-badges {
    flex-direction: column; /* Stack badges vertically */
    margin-top: 0; /* Adjust margin for tighter spacing */
  }

  .app-badges p {
    margin-right: 0; /* Remove margin for stacked layout */
  }

  .steps {
    flex-direction: column; /* Stack steps vertically */
    align-items: center; /* Center align the steps */
  }

  .step {
    width: 80%; /* More manageable width for steps */
    margin-bottom: 2rem; /* Increase margin for clarity */
  }
}

/* Intermediate devices (Tablets) */
@media (min-width: 769px) and (max-width: 1024px) {
  .hero {
    gap: 1.5rem; /* Slightly larger gap for tablet */
  }

  .hero-content, .hero-image {
    text-align: left; /* Maintain text alignment for readability */
  }

  .vision-point {
    flex-basis: 50%; /* Two columns for vision points */
  }

  .services-grid, .feature-grid {
    grid-template-columns: repeat(2, 1fr); /* Two columns for service and feature items */
  }

  .app-badges {
    flex-direction: row; /* Inline badges for wider screens */
    justify-content: space-around; /* Even spacing for aesthetics */
  }
}