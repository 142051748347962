.signup-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
}

.signup-container h2 {
  text-align: center;
  color: #7161EB;
  font-family: 'Garet', sans-serif;
}

.signup-form {
  display: flex;
  flex-direction: column;
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.form-group {
  flex: 1;
  position: relative;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #242659;
  font-family: 'Garet', sans-serif;
}

.form-group input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: 'Garet', sans-serif;
}

.zipcode-instruction {
  font-size: 0.8em;
  color: #888;
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
  font-family: 'Garet', sans-serif;
}

.zipcode-suggestions {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  background-color: white;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  font-family: 'Garet', sans-serif;
}

.zipcode-suggestions li {
  padding: 10px;
  cursor: pointer;
  font-family: 'Garet', sans-serif;
}

.zipcode-suggestions li:hover {
  background-color: #f0f0f0;
}

.checkbox-group {
  margin-bottom: 1.5rem;
}

.checkbox-group p {
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #242659;
  font-family: 'Garet', sans-serif;
}

.checkbox-row {
  display: flex;
  gap: 2rem;
}

.checkbox-row label {
  display: flex;
  align-items: center;
  font-family: 'Garet', sans-serif;
}

.checkbox-row input[type="checkbox"] {
  margin-right: 0.5rem;
  font-family: 'Garet', sans-serif;
}

.signup-button {
  background-color: #7161EB;
  color: white;
  border: none;
  width: 105%;
  padding: 1rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Garet', sans-serif;
  transition: background-color 0.3s ease;
}

.signup-button:hover {
  background-color: #5c4ed6;
}

.signup-button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

@media (max-width: 600px) {
  .form-row {
    flex-direction: column;
    gap: 1rem;
  }

  .checkbox-row {
    flex-direction: column;
    gap: 0.5rem;
  }

  .zipcode-instruction {
    font-size: 0.7em;
    font-family: 'Garet', sans-serif;
  }
}