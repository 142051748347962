.contact-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.contact-content h1 {
  color: #7161EB;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-family: 'Garet', sans-serif;
}

.contact-content > p {
  color: #242659;
  font-size: 1.1rem;
  font-family: 'Garet', sans-serif;
}

.contact-form {
  margin-bottom: 2rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  color: #242659;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-family: 'Garet', sans-serif;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  font-family: 'Garet', sans-serif;
}

.form-group textarea {
  height: 150px;
  resize: vertical;
  font-family: 'Garet', sans-serif;
}

.cta-button-contact {
  background-color: #7161EB;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 103%;
  font-family: 'Garet', sans-serif;
}

.cta-button-contact:hover {
  background-color: #5c4ed6;
  font-family: 'Garet', sans-serif;
}

.cta-button-contact:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.contact-info {
  width: 97%;
  background-color: #f0f0f0;
  padding: 2rem;
  border-radius: 8px;
  font-family: 'Garet', sans-serif;
}

.contact-info h2 {
  color: #7161EB;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-family: 'Garet', sans-serif;
}

.contact-info p {
  color: #242659;
  margin-bottom: 0.5rem;
  font-family: 'Garet', sans-serif;
}

@media (max-width: 768px) {
  .contact-content {
    font-family: 'Garet', sans-serif;
    padding: 1.5rem;
  }

  .contact-content h1 {
    font-size: 2rem;
    font-family: 'Garet', sans-serif;
  }

  .contact-info {
    font-family: 'Garet', sans-serif;
    padding: 1.5rem;
  }
}