.footer {
  background-color: #242659;
  color: white;
  padding: 3rem 0 1rem;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.disabled {
  pointer-events: none;
}

.footer-section {
  flex-basis: 22%;
  margin-bottom: 2rem;
}

.footer-section h3 {
  color: white;
  margin-bottom: 1rem;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 0.5rem;
}

.footer-section a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.app-store-badge-footer,
.google-play-badge-footer {
  color: white;
  font-size: 2rem;
  margin: 0 0.5rem;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.social-icons a {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: #7161EB;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.social-icons a:hover {
  background-color: white;
  color: #7161EB;
}

.footer-btn {
  display: inline-block;
  background-color: #7161EB;
  color: white;
  padding: 1rem 2rem;
  border-radius: 10px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  border: none;
  cursor: pointer;
}

.footer-bottom {
  text-align: center;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255,255,255,0.1);
}

.footer-bottom a {
  color: #7161EB;
  text-decoration: none;
}

@media (max-width: 768px) {
  .footer-section {
    flex-basis: 100%;
    text-align: center;
  }

  .social-icons {
    justify-content: center;
  }

  .newsletter-form {
    flex-direction: column;
    align-items: center;
  }

  .newsletter-form input,
  .newsletter-form button {
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 4px;
  }

  .newsletter-form button {
    width: 105%;
  }
}