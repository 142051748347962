.header {
  background-color: #7161EB;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 2rem;
}

.logo {
  height: 30px;
  width: auto;
}


.nav ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav ul li {
  margin-left: 2rem;
}

.nav ul li a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav ul li a:hover {
  color: #f4f7f4;
}

.nav-toggle {
  display: none;
  font-size: 1.5rem;
  color: #242659;
  cursor: pointer;
}

@media (max-width: 768px) {
  .header-content {
    padding: 1rem;
  }

  .nav {
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: #7161EB;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
  }

  .nav-open {
    transform: translateX(0);
  }

  .nav ul {
    flex-direction: column;
    padding: 1rem;
  }

  .nav ul li {
    margin: 1rem 0;
  }

  .nav-toggle {
    display: block;
  }
}