.about {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  color: #242659;
}

.about-hero {
  text-align: center;
  margin-bottom: 3rem;
}

.about-hero h1 {
  font-size: 3rem;
  font-family: 'Garet', sans-serif;
  color: #7161EB;
  margin-bottom: 1rem;
}

.tagline {
  font-size: 1.5rem;
  font-style: italic;
  font-family: 'Garet', sans-serif;
}

.our-story, .for-sitters, .our-mission {
  margin-bottom: 3rem;
  font-family: 'Garet', sans-serif;
}

h2 {
  font-size: 2rem;
  color: #7161EB;
  margin-bottom: 1rem;
  font-family: 'Garet', sans-serif;
}

.founder-info, .buddy-info {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
  font-family: 'Garet', sans-serif;
}

.founder-image, .buddy-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
}

.cta-button-about {
  display: inline-block;
  background-color: #7161EB;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  font-family: 'Garet', sans-serif;
  transition: background-color 0.3s ease;
}

.cta-button-about:hover {
  background-color: #5c4ed6;
}

@media (max-width: 768px) {
  .founder-info, .buddy-info {
    flex-direction: column;
    text-align: center;
    font-family: 'Garet', sans-serif;
  }

  .founder-image, .buddy-image {
    margin-bottom: 1rem;
  }
}